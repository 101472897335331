<template>
  <div class="login-div">
    <b-navbar></b-navbar>

    <div class="login-card-div w-50  p-5">
      <!-- <div style="font-size: 16px;font-weight: 600;">Welcome Nice to have you on our platform</div>
      <div
        class="p-2"
        style="color: #767676;font-size: 13px;font-weight: 600;"
      >Login to have exciting journey with us</div>-->
      <div class="mx-4 px-4 py-3">
        <label class="login-input-label">Email Address</label>
        <w-text-input
          :fieldType="'text'"
          :disabled="false"
          :defaultValue="emailValue"
          :labelStyle="'register-label'"
          :validation="''"
          :placeholder="'Your Email'"
          :fieldName="'Email Address'"
          @textInputChange="getEmailAddress($event)"
        />
      </div>
      <div class="mx-4 px-4 py-3">
        <label class="login-input-label">Password</label>
        <w-text-input
          :fieldType="'password'"
          :disabled="false"
          :defaultValue="passwordValue"
          :labelStyle="'register-label'"
          :validation="''"
          :fieldName="'Password'"
          :placeholder="'Your Password'"
          @textInputChange="getPassword($event)"
        />
      </div>
      <div class="mx-4 px-4 py-3">
        <b-button
          class="w-100"
          variant="danger"
          @click="loginButton()"
          :disabled="isloginDetail"
          >Login</b-button
        >
      </div>
      <div>
        <span style="font-size: 12px;font-weight: 600;color: black;"
          >Forget your password ?</span
        >
        <span class="login-link-anchor ml-1" @click="resetPassword">Click here</span>
      </div>
      <div>
        <span
          style="font-size: 13px!important;font-weight: 600;"
          class="login-link-anchor"
          @click="registerButton()"
          >Register</span
        >
      </div>
    </div>

    <!-- error modal -->
    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
    />
  </div>
</template>

<script>
import Navbar from "../../widgets/BrandNavbar";
import TextInput from "../../widgets/TextInput.vue";
import Modal from "../../widgets/ModalWidget";
import { login } from "../../Service/LoginService";
export default {
  components: {
    "b-navbar": Navbar,
    "w-text-input": TextInput,
    Modal,
  },
  data() {
    return {
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,

      emailValue: "",
      passwordValue: "",
    };
  },

  computed: {
    isloginDetail() {
      if (!this.emailValue == "" && !this.passwordValue == "") {
        return false;
      }
      return true;
    },
  },

  methods: {
    getEmailAddress(event) {
      this.emailValue = event;
    },
    getPassword(event) {
      this.passwordValue = event;
    },

    loginButton() {
      let data = {
        username: this.emailValue,
        password: this.passwordValue,
      };
      login(data)
        .then((res) => {
          if(res && res.access_token){
            sessionStorage.setItem("token", res.access_token);
            sessionStorage.setItem("companyID",res.company_id);
            this.$router.push("/dashboard");
          }
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.detail;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
      // if(this.emailValue == 'hectocommerce@diatoz.com' && this.passwordValue == 'admin'){
      //     this.$router.push("/dashboard");
      // }else{
      //   this.alert = true;
      //   this.alertMsg = 'Invalid credentials';
      //   this.alertType = "Failure";
      //   this.isConfirmAlert = false;
      // }
    },

    registerButton() {
      // this.$emit("showRegistration");
      this.$router.push("/pre-register");
    },

    resetPassword(){
      this.$router.push("/resetPassword")
    },

    // close modal
    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },
  },
};
</script>

<style scoped>
/* .login-div {
  position: absolute;
  width: 100%;
  height: -webkit-fill-available;
} */
.login-card-div {
  position: absolute;
  /* top: 20%; */
  /* width: 50%; */
  /* margin-left: 25%; */
  background: white;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.login-input-label {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 12px;
  font-weight: 600;
  float: left;
  color: black;
}
.login-link-anchor {
  color: red;
  font-size: 13px;
  cursor: pointer;
}
.login-link-anchor:hover {
  color: red;
  text-decoration: underline;
}

@media screen and (min-width: 576px) and (max-width: 992px){
  .login-card-div{
    top: 55% !important;
    width: 75% !important;
  }
}
/* @media screen and (min-width: 576px) and (max-width: 768px){
  .login-card-div{
    top: 62% !important;
  }
} */
@media screen and (min-width: 320px) and (max-width: 575px){
  .login-card-div{
    top: 55% !important;
    width: 95% !important;
    padding: 5px !important;
  }
  ::v-deep .brand-navbar .navbar-nav{
    width: 100% !important;
  }
  ::v-deep .brand-navbar .logo{
    margin-left: 0;
    font-size: 20px;
  }
  ::v-deep .navbar-toggler{
    padding: .25rem .50rem !important;
    font-size: 12px !important;

  }
}
</style>
